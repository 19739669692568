import { Link, Text } from '@chakra-ui/react';
import React from 'react';
import { Page } from '../components/Page';
import { PageHeader } from '../components/PageHeader';
import { seo } from '../components/Seo';

export const Head = seo({ title: 'Resources' });

export default function ResourcsPage() {
  return (
    <Page>
      <PageHeader title="Resources" />
      <Text>
        As we rebuild in Aotearoa, we aim to provide resources tailored for our region. For now, our parent body{' '}
        <abbr title="Wales, Ireland, Scotland, and England Regional Administration">WISE-RA</abbr> has{' '}
        <Link href="https://iww.org.uk/resource/" isExternal>
          a collection of resources
        </Link>{' '}
        that are applicable internationally.
      </Text>
    </Page>
  );
}
